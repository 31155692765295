@import url(https://fast.fonts.net/lt/1.css?apiType=css&c=1bc03b04-168e-4b6c-987b-255497d5b582&fontids=5167167,5317098);
@font-face {
  font-family: "Bambino W05 Regular";
  src:
    url(/static/media/413cc05d-38db-4d3c-8a8d-351aac6f633a.3fd3288d.eot?#iefix);
  src:
    url(/static/media/413cc05d-38db-4d3c-8a8d-351aac6f633a.3fd3288d.eot?#iefix) format("eot"),
    url(/static/media/ca075ab8-7f19-4a44-9bdc-770dd6b21fc9.56c94902.woff2) format("woff2"),
    url(/static/media/90800040-efcb-4c44-bbce-f4f5c871e397.6ea90c3b.woff) format("woff"),
    url(/static/media/bc3b8c78-bb52-4907-ba72-db1fb25ac915.8013efcf.ttf) format("truetype");
}

@font-face {
  font-family: "Bambino W05 Black";
  src:
    url(/static/media/3a750f83-a8d8-4872-bbc7-0c5884dbf9ad.ad249506.eot?#iefix);
  src:
    url(/static/media/3a750f83-a8d8-4872-bbc7-0c5884dbf9ad.ad249506.eot?#iefix) format("eot"),
    url(/static/media/b725a556-0c21-4299-98ae-60da8449acd3.576fdc58.woff2) format("woff2"),
    url(/static/media/3384a86e-405f-49fa-8321-a4010beacef2.55d80585.woff) format("woff"),
    url(/static/media/6e9b4db6-48f5-448e-be4f-9f7e171dbebb.ad3658bc.ttf) format("truetype");
}

@font-face {
  font-family: "OverpassMono-Regular";
  src:
    url(/static/media/OverpassMono-Regular.9e696f55.ttf) format("truetype");
}

body {
  /* touch-action: none; */
  margin: 0;
  font-family: "Bambino W05 Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "OverpassMono-Regular";
}

pre {
  font-family: Menlo;
  font-size: 10pt;
}

code {
  font-family: "OverpassMono-Regular";
}

/*
  Dough: #D6D2C4
  Charcoal: #000000
  Wood: #B9975B
  Flame: #CB6015

Bambino W05 Regular
Bambino W05 Black
OverpassMono-Regular

*/

.menu {
  background-color: #D6D2C4;
}

.menu .header {
  font-family: "Bambino W05 Black";
  width: 100%;
  text-align: center;
}

.menuCategory {
  background-color: white;
  border-top: 4px solid black;
}

.menuCategory h1 {
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  background-color: #000000;
  color: #D6D2C4;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
}

.menuCategory.alt h1 {
  background-color: #B9975B;
  color: #000000;
}

.menuCategory .item:first-of-type :not(.landscape) {
  border-top-style: none;
}

